import PreviewIcon from "@mui/icons-material/Preview";
import {
  Box,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Header from "../../../components/header";
import Status from "../../../components/status";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { get } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import {
  DEFAULT_MONTH_FORMAT,
  formatDate,
  getDayOfWeek,
  getMonthOptionsIncludingLastYear
} from "../../../utils/TimeUtil";

const DEFAULT_SEARCH_CRITERIA = {
  monthYear: moment().month() + "|" + moment().isoWeekYear(),
  from: moment().startOf("month"),
  to: moment().endOf("month")
};

const ClassReportDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();
  const { classId } = useParams();

  const [data, setData] = useState([]);
  const [className, setClassName] = useState("");
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);
  const [viewBy, setViewBy] = useState("QUANTITY");
  const [showAttendance, setShowAttendance] = useState(false);
  const [studentAttendanceData, setStudentAttendanceData] = useState([]);

  const columns = [
    {
      field: "studentName",
      label: "classReport.table.field.studentName.label",
      sortable: false
    },
    {
      field: "completedQuantity",
      label: "classReport.table.field.completedQuantity.label",
      align: "center",
      valueGetter: ({ completedQuantity, totalSessions, viewBy }) =>
        getReportNumber(completedQuantity, totalSessions, viewBy)
    },
    {
      field: "absenceQuantity",
      label: "classReport.table.field.absenceQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({
        madeUpQuantity,
        madeUpUncompletedQuantity,
        absenceQuantity,
        totalSessions,
        viewBy
      }) =>
        getReportNumber(
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          totalSessions,
          viewBy
        )
    },
    {
      field: "madeUpQuantity",
      label: "classReport.table.field.madeUpQuantity.label",
      sortable: false,
      align: "center",
      valueGetter: ({ madeUpQuantity, madeUpUncompletedQuantity, absenceQuantity, viewBy }) =>
        getReportNumber(
          madeUpQuantity,
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          viewBy
        )
    },
    {
      field: "madeUpUncompletedQuantity",
      label: "classReport.table.field.madeUpUncompletedQuantity.label",
      align: "center",
      valueGetter: ({ madeUpQuantity, madeUpUncompletedQuantity, absenceQuantity, viewBy }) =>
        getReportNumber(
          madeUpUncompletedQuantity,
          madeUpQuantity + madeUpUncompletedQuantity + absenceQuantity,
          viewBy
        )
    },
    {
      field: "loaQuantity",
      label: "classReport.table.field.loaQuantity.label",
      valueGetter: ({ loaQuantity, totalSessions, viewBy }) =>
        getReportNumber(loaQuantity, totalSessions, viewBy)
    }
  ];
  const rowActions = [
    {
      icon: <PreviewIcon />,
      tooltip: t("classReport.table.field.scheduleHistories"),
      action: ({ studentUuid, classUuid }) => {
        get(`${UrlPaths.STUDENTS}/${studentUuid}/histories`, {
          ...searchCriteria,
          classUuid
        })
          .then((res) => {
            setStudentAttendanceData(res);
            setShowAttendance(true);
          })
          .catch(console.debug);
      }
    }
  ];

  useEffect(() => {
    refreshData();
  }, [branchInfo.value, searchCriteria, classId]);

  useEffect(() => {
    setData(data.map((x) => ({ ...x, viewBy })));
  }, [viewBy]);

  const refreshData = () => {
    if (branchInfo.value.uuid && classId) {
      get(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/classReports/${classId}`, {
        ...searchCriteria
      })
        .then((res) => {
          const { classUuid, className, studentReports } = res;
          setClassName(className);
          setData(
            studentReports.map((x) => ({
              ...x,
              classUuid,
              className,
              viewBy
            }))
          );
        })
        .catch(console.debug);
    }
  };

  const handleMonthChange = (monthYear) => {
    const { from, to } = getMonthOptionsIncludingLastYear().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    setSearchCriteria({
      from: from,
      to: to,
      monthYear
    });
  };

  const getReportNumber = (actual, total, viewBy) => {
    if (viewBy === "QUANTITY") {
      return actual === 0 ? 0 : `${actual} / ${total}`;
    } else {
      const percentage = (actual / total) * 100;
      return `${isNaN(percentage) || percentage === 0 ? 0 : percentage.toFixed(2)}%`;
    }
  };

  return (
    <Box>
      {/* SEARCH & ACTIONS BAR */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title={t("header.title.label")} />
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
          <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>{className}</Typography>
        </Grid>
        <Grid
          item
          xs={4}
          borderRadius="3px"
          sx={{ display: "flex", alignItems: "center", backgroundColor: colors.grey[900] }}>
          <FormControl
            sx={{
              paddingX: "0.5rem",
              display: "flex",
              flexDirection: "row",
              alignItems: "center"
            }}>
            <FormLabel id="status-label" sx={{ paddingRight: "0.5rem" }}>
              {t("classReport.placeholder.viewBy")}
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="status-label"
              value={viewBy}
              name="row-radio-buttons-group"
              onChange={(e) => {
                setViewBy(e.target.value);
              }}>
              <FormControlLabel
                value="QUANTITY"
                control={<Radio />}
                label={t("classReport.viewBy.quantity.label")}
              />
              <FormControlLabel
                value="PERCENTAGE"
                control={<Radio />}
                label={t("classReport.viewBy.percentage.label")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
            <InputLabel id="class-status-label" color="neutral">
              {t("makeupClass.placeholder.monthOptions")}
            </InputLabel>
            <Select
              labelId="class-status-label"
              id="class-status"
              label={t("makeupClass.placeholder.monthOptions")}
              value={searchCriteria.monthYear}
              onChange={(e) => handleMonthChange(e.target.value, true)}
              MenuProps={{
                style: { zIndex: 15002 }
              }}
              sx={{ width: "100%" }}>
              {getMonthOptionsIncludingLastYear().map(({ month, year, from, to }, index) => (
                <MenuItem key={index} value={month + "|" + year}>
                  {t("makeupClass.monthOptions.label", {
                    month: month + 1,
                    year,
                    startDayOfMonth: from.format(DEFAULT_MONTH_FORMAT),
                    endDayOfMonth: to.format(DEFAULT_MONTH_FORMAT)
                  })}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Box>
      <Box
        backgroundColor={colors.primary[400]}
        display="flex"
        mb="0.5rem"
        p="0.5rem"
        justifyContent="space-between">
        <Table columnConfig={columns} data={data} rowActions={rowActions} />
      </Box>
      <Dialog
        open={!!showAttendance}
        onClose={() => setShowAttendance(false)}
        onConfirm={() => setShowAttendance(false)}
        width="60%"
        buttons={DialogButtons.OK}
        title={t("sessionsDebts.attendanceDetails.label")}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
            {studentAttendanceData.map((history, index) => (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <CardContent
                  key={index}
                  sx={{ background: colors.grey[900], margin: "0.5rem", borderRadius: "5px" }}>
                  <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    {t(`timetable.weekDay.${getDayOfWeek(history.weekDay)}.label`)}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {formatDate(history.scheduleDate)}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {history.timeSlot}
                  </Typography>
                  <Status
                    prefix="attendance.historyStatus"
                    status={history.historyStatus}
                    type="HISTORY"
                  />
                </CardContent>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ClassReportDetails;
