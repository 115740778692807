import moment from "moment";

export const DEFAULT_DATE_FORMAT = "DD/MM/YYYY";

export const DEFAULT_MONTH_FORMAT = "DD/MM";

export const getMonthOptions = () => {
  const monthOptions = [];
  for (let i = 0; i <= 11; i++) {
    const m = moment().month(i);
    const startDayOfMonth = m.clone().startOf("month");
    const endDayOfMonth = m.clone().endOf("month");
    monthOptions.push({
      month: m.month(),
      year: m.isoWeekYear(),
      from: startDayOfMonth,
      to: endDayOfMonth
    });
  }
  return monthOptions;
};

export const getMonthOptionsIncludingLastYear = (monthsToShow = 3) => {
  const monthOptions = [];
  const lastYear = moment().year() - 1;
  // last 3 months from last year
  for (let i = 12 - monthsToShow; i <= 11; i++) {
    const m = moment().year(lastYear).month(i);
    const startDayOfMonth = m.clone().startOf("month");
    const endDayOfMonth = m.clone().endOf("month");
    monthOptions.push({
      month: m.month(),
      year: m.isoWeekYear(),
      from: startDayOfMonth,
      to: endDayOfMonth
    });
  }
  return [...monthOptions, ...getMonthOptions()];
};

export const getDayOfWeek = (weekDay) => {
  switch (weekDay) {
    case 1:
      return "mon";
    case 2:
      return "tue";
    case 3:
      return "wed";
    case 4:
      return "thu";
    case 5:
      return "fri";
    case 6:
      return "sat";
    case 7:
    default:
      return "sun";
  }
};

export const getWeekDay = (dayOfWeek) => {
  switch (dayOfWeek) {
    case "MONDAY":
      return 1;
    case "TUESDAY":
      return 2;
    case "WEDNESDAY":
      return 3;
    case "THURSDAY":
      return 4;
    case "FRIDAY":
      return 5;
    case "SATURDAY":
      return 6;
    case "SUNDAY":
      return 7;
    default:
      return dayOfWeek;
  }
};

export const toDayOfWeekShorten = (dayOfWeek) =>
  dayOfWeek ? dayOfWeek.substring(0, 3).toLowerCase() : "";

export const formatDate = (dateStr, pattern = DEFAULT_DATE_FORMAT) => {
  moment.defaultFormat = DEFAULT_DATE_FORMAT;
  if (!dateStr) {
    return "";
  }

  const timeInMill = new Date(dateStr).getTime();
  const momentDate = isNaN(timeInMill) ? moment(dateStr) : moment(timeInMill);
  return momentDate.format(pattern);
};

export const getTimeSlots = (timeSlots) => {
  const slots = [];
  timeSlots.forEach((slot) => {
    const start = moment.utc(slot.split("-")[0], "HH:mm");
    const end = moment.utc(slot.split("-")[1], "HH:mm");
    while (start.isSameOrBefore(end)) {
      if (slots.find((s) => s === start.format("HH:mm")) === undefined) {
        slots.push(start.format("HH:mm"));
      }
      start.add(30, "minutes");
    }
  });
  return slots;
};

export const getAge = (birthday) => {
  const year = moment(birthday).year();
  return moment().year() - year;
};
