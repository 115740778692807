import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  Grid,
  InputLabel,
  Table as MUITable,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Dialog, { DialogButtons } from "../../../components/dialog";
import Table from "../../../components/table";
import UrlPaths from "../../../constants/UrlPaths";
import { getWithoutLoading } from "../../../services/HttpClient";
import { branchInfo } from "../../../signals";
import { tokens } from "../../../theme";
import { toVNDCurrency } from "../../../utils/CurencyUtil";
import { DEFAULT_MONTH_FORMAT, getMonthOptionsIncludingLastYear } from "../../../utils/TimeUtil";

const INITIAL_DATA = [
  {
    className: "",
    revenue: []
  }
];

const DEFAULT_SEARCH_CRITERIA = {
  monthYear: moment().month() + "|" + moment().isoWeekYear(),
  month: moment().month() + 1
};

const ActualFinanceReports = ({ defaultExpanded }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [actualFinancialReports, setActualFinancialReports] = useState([]);
  const [data, setData] = useState(INITIAL_DATA);
  const [fetching, setFetching] = useState(false);
  const [selectedClassName, setSelectedClassName] = useState("");
  const [searchCriteria, setSearchCriteria] = useState(DEFAULT_SEARCH_CRITERIA);

  const classReportDetailColumns = [
    {
      field: "id",
      label: "actualFinancialReports.details.table.field.id.label",
      sortable: false
    },
    {
      field: "studentName",
      label: "actualFinancialReports.details.table.field.studentName.label"
    },
    {
      field: "consumedSessions",
      align: "center",
      label: "actualFinancialReports.details.table.field.consumedSessions.label"
    },
    {
      field: "remainingSessions",
      align: "center",
      label: "actualFinancialReports.details.table.field.remainingSessions.label"
    },
    {
      field: "debtSessions",
      align: "center",
      label: "actualFinancialReports.details.table.field.debtSessions.label"
    },
    {
      field: "singlePrice",
      align: "center",
      label: "actualFinancialReports.details.table.field.singlePrice.label",
      valueGetter: ({ singlePrice }) => toVNDCurrency(singlePrice)
    },
    {
      field: "revenue",
      label: "actualFinancialReports.details.table.field.revenue.label",
      align: "center",
      valueGetter: ({ revenue }) => toVNDCurrency(revenue)
    }
  ];

  useEffect(() => {
    if (branchInfo.value.uuid) {
      refreshData();
    }
  }, [branchInfo.value, searchCriteria]);

  const refreshData = () => {
    setFetching(true);
    getWithoutLoading(`${UrlPaths.ADMIN}/statistics/${branchInfo.value.uuid}/revenue/actual`, {
      month: searchCriteria.month,
      year: searchCriteria.year
    })
      .then((res) => {
        setData(res);
        setFetching(false);
      })
      .catch((err) => {
        console.debug(err);
        setFetching(false);
      });
  };

  const handleMonthChange = (monthYear) => {
    const targetMonth = getMonthOptionsIncludingLastYear().find(({ month, year }) => {
      return month + "|" + year === monthYear;
    });
    setSearchCriteria({
      ...searchCriteria,
      month: targetMonth.month + 1,
      year: targetMonth.year,
      monthYear
    });
  };

  return (
    <>
      <Accordion defaultExpanded={defaultExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{
            backgroundColor: colors.greenAccent[600]
          }}>
          <Typography variant="h4" fontWeight="bolder">
            {t("actualFinancialReports.details.title")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {fetching ? (
            <>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </>
          ) : (
            <>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <Grid container mb="1rem" columnSpacing={1}>
                  <Grid item xs={4}>
                    <FormControl sx={{ width: "100%", paddingX: "0.5rem" }} size="small">
                      <InputLabel id="class-status-label" color="neutral">
                        {t("disabledStudents.placeholder.monthOptions")}
                      </InputLabel>
                      <Select
                        labelId="class-status-label"
                        id="class-status"
                        label={t("disabledStudents.placeholder.monthOptions")}
                        value={searchCriteria.monthYear}
                        onChange={(e) => handleMonthChange(e.target.value, true)}
                        MenuProps={{
                          style: { zIndex: 15002 }
                        }}
                        sx={{ width: "100%" }}>
                        {getMonthOptionsIncludingLastYear().map(
                          ({ month, year, from, to }, index) => (
                            <MenuItem key={index} value={month + "|" + year}>
                              {t("disabledStudents.monthOptions.label", {
                                month: month + 1,
                                year,
                                startDayOfMonth: from.format(DEFAULT_MONTH_FORMAT),
                                endDayOfMonth: to.format(DEFAULT_MONTH_FORMAT)
                              })}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>
              <Box
                backgroundColor={colors.primary[400]}
                display="flex"
                mb="0.5rem"
                p="0.5rem"
                justifyContent="space-between">
                <TableContainer component={Paper}>
                  <MUITable aria-label="spanning table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell align="center">
                          {t("actualFinancialReports.table.field.id.label")}
                        </TableCell>
                        <TableCell align="center">
                          {t("actualFinancialReports.table.field.className.label")}
                        </TableCell>
                        {(data || []).length > 0
                          ? data[0].revenue.map(({ from, to }, index) => (
                              <TableCell key={index}>
                                {t("actualFinancialReports.table.field.weeks.label", {
                                  week: moment(from).isoWeek(),
                                  year: moment(from).isoWeekYear(),
                                  startDayOfWeek: moment(from).format(DEFAULT_MONTH_FORMAT),
                                  endDayOfWeek: moment(to).format(DEFAULT_MONTH_FORMAT)
                                })}
                              </TableCell>
                            ))
                          : null}
                        <TableCell align="center">
                          {t("actualFinancialReports.table.field.totalAmount.label")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data.map(({ className, revenue, classStudentRevenue }, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">{index + 1}</TableCell>
                          <TableCell align="center">
                            <Link
                              onClick={() => {
                                setSelectedClassName(className);
                                setActualFinancialReports(
                                  classStudentRevenue.map((x, index) => ({ ...x, id: index + 1 }))
                                );
                              }}>
                              <Typography
                                sx={{ fontWeight: "bold", color: colors.greenAccent[400] }}>
                                {className}
                              </Typography>
                            </Link>
                          </TableCell>
                          {revenue.map(({ amount }, index) => (
                            <TableCell key={index}>{toVNDCurrency(amount)}</TableCell>
                          ))}
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            {toVNDCurrency(
                              revenue.reduce((totalAmount, { amount }) => {
                                totalAmount += amount || 0;
                                return totalAmount;
                              }, 0)
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                      {data?.length > 0 && (
                        <TableRow key="total">
                          <TableCell align="center">-</TableCell>
                          <TableCell align="center">
                            <Typography sx={{ fontWeight: "bold" }}>
                              {t("statistics.total.label")}
                            </Typography>
                          </TableCell>
                          {data[0].revenue.map((_, index) => (
                            <TableCell key={index}>
                              {toVNDCurrency(
                                data
                                  .map(({ revenue }) =>
                                    revenue
                                      .filter((_, i) => i === index)
                                      .reduce((totalAmount, { amount }) => {
                                        totalAmount += amount || 0;
                                        return totalAmount;
                                      }, 0)
                                  )
                                  .reduce((total, amount) => {
                                    total += amount || 0;
                                    return total;
                                  }, 0)
                              )}
                            </TableCell>
                          ))}
                          <TableCell align="center" sx={{ fontWeight: "bold" }}>
                            {toVNDCurrency(
                              data
                                .map(({ revenue }) =>
                                  revenue.reduce((totalAmount, { amount }) => {
                                    totalAmount += amount || 0;
                                    return totalAmount;
                                  }, 0)
                                )
                                .reduce((total, amount) => {
                                  total += amount || 0;
                                  return total;
                                }, 0)
                            )}
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </MUITable>
                </TableContainer>
              </Box>
            </>
          )}
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={actualFinancialReports.length > 0}
        width="80vw"
        onClose={() => setActualFinancialReports([])}
        onConfirm={() => setActualFinancialReports([])}
        buttons={DialogButtons.OK}
        title={t("actualFinancialReports.classDetails.title", { className: selectedClassName })}>
        <Box>
          <Typography variant="h5" sx={{ marginY: "1rem" }}>
            {t("actualFinancialReports.totalAmount.label")}:&nbsp;
            <b>
              {toVNDCurrency(
                actualFinancialReports.reduce((totalAmount, { revenue }) => {
                  totalAmount += revenue || 0;
                  return totalAmount;
                }, 0)
              )}
            </b>
          </Typography>
        </Box>
        <Table
          showHeaderToolbar={false}
          showFooterToolbar={false}
          columnConfig={classReportDetailColumns}
          data={actualFinancialReports}
        />
      </Dialog>
    </>
  );
};

export default ActualFinanceReports;
